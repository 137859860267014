import * as React from 'react';
import styled from 'styled-components';
// import SignUpForm, {SignUpFormProps} from './SignUpForm';
import {CenterSectionCSS, Media, Palette} from '../../constants';
import {HeroSection} from "../../components/HeroSection";
import {TwoToneText} from "../../components/TwoToneText";
import {TextLogo} from "../../components/TextLogo";
import {HighlightScribble} from "../../components/HighlightScribble";
// @ts-ignore
import ListTick from '../../images/tick.svg';


export type CoverContentProps = {} ;

export default function AboutHero({}: CoverContentProps) {
    return (
        <HeroSection>
            <Container>
                <Horizontal>
                    <Left>
                        <Title/>
                        <Strapline />
                        {/*<Scribble/>*/}
                    </Left>
                    <Right>
                        <Blurb />
                        <Values/>
                    </Right>
                </Horizontal>
                <Vertical>
                    <Title/>
                    <Strapline/>
                    <Blurb/>
                    {/*<Scribble/>*/}
                    <Values/>
                </Vertical>
            </Container>
        </HeroSection>
    )
}

function Title() {
    return <h1 className={'title'}><TwoToneText w1={'Our'} w2={'Mission'}/></h1>;
}
function Strapline() {
    return <div className={'strapline'}><div>Digital Life Support&nbsp;</div><div>for&nbsp;</div> <div>Internet Addicts</div></div>;
}

function Blurb() {
    return <div className={'blurb'}>
        <p><TextLogo/> helps porn users who are trying to quit or reduce their usage, right in the moment of temptation, by offering positive alternatives.</p>
        <p>Our mission is to be a psychologically helpful ally - not just a blocker. We are always on the side of the user, working <em>with</em> them, not against them.</p>
    </div>;
}

function Scribble() {
    return <HighlightScribble className={'scribble'}>
        <TextLogo/> was born from personal experience
    </HighlightScribble>
}

function Values() {
    return <div className={'values'}>
        <h3>Our Core Values:</h3>
        <ul>
            <li>Realtime Support</li>
            <li>No coercions or blocks by default; It is <em>your</em> choice</li>
            <li>Psychologically savvy interventions</li>
        </ul>
    </div>
}



const Container = styled.section`
  ${CenterSectionCSS};
  max-width: 100rem;  
  margin: 0 auto ;
  color: white ;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
  padding-bottom: 4em ;
  border-bottom: 1px solid ${Palette.Yellow.hash};

  position: relative;
  border-bottom: none;

  .strapline {
    font-weight: 500;
    font-size: 2em;
    line-height: 1.5em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    &>* {
      flex: 0 0 auto;
      max-width: 100%;
    }
  }
  
  .values {
    h3 {
      font-weight: bold;
    }
    li {
      list-style: none;
      margin-bottom: 0.2em;
      &::before {
        content: '';
        height: 1em;
        width: 1em;
        display: inline-block;
        margin-right: 1em;
        background-image: url(${ListTick});
        background-size: cover;    
      }
    }
  }

`;
const Left = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column ;
  padding-left: 8rem;
  padding-right: 2rem;

  .scribble {
    left: 8em;
    padding-top: 4em;
  }
`;
const Right = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column ;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Horizontal = styled.div`
  display: none;
  @media (${Media.laptop}) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .signupForm {
    max-width: 23.5em;
  }
  
  .blurb {
    margin-bottom: 2em;
  }
`;
const Vertical = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2em;
  text-align: center;
  align-items: center;

  .strapline {
    padding-bottom: 2em;
    justify-content: center;
  }
  
  .scribble {
    left: 30%;
    margin-left: -7em;
    margin-top: 1em;
    margin-bottom: 2em;
  }
  
  .values {
    max-width: 30em;
    ul {
      text-align: left;    
    }
  }

  @media (${Media.laptop}) {
    display: none;
  }
`;
