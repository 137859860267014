import React from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../../utils";
import {TwoToneText} from "../../components/TwoToneText";
import {TextLogo} from "../../components/TextLogo";
import {CenterSectionCSS, Media} from "../../constants";


export type TheHistoryProps = {} & IntrinsicElementProps;

export function TheHistory({...props}: TheHistoryProps) {
    return <Container {...props}>
        <h2><TwoToneText w1={'The'} w2={'History'}/></h2>
        {/*<div className={'row'}>*/}
        {/*    <div className={'A'}>*/}
                <div className={'blurb'}>
                    <p><TextLogo/> was born from personal experience;</p>
                    <p>Porn was messing with my life; my relationships, my career, my health... I wanted to quit. But somehow I couldn't.</p>
                    <p>I realised that it had become an addiction.</p>
                    <p>At some level, most addicts don't <em>want</em> to be addicts. But for deep psychological reasons it can feel impossibly difficult to 'just quit'.</p>
                    <p>No matter how many tools, strategies and support networks we learn and build, there are times when we open our laptops or turn on our phones and... in that moment, we have already lost the fight.</p>
                    <p>Sure, we could theoretically reach out for support. But as every addict knows, the shame, guilt, despair (and sometimes just the time of night) means we simply cannot.</p>
                    <p><strong>What we really need is a buddy</strong> to step in on our behalf and catch us in that moment. And we need them <em>right there and then</em>.</p>
                    <p>We need someone to have our backs. Someone who gets it. Someone who can help us make a different choice with kindness and compassion. Someone who will not judge us or make the shame worse.</p>
                    <p><TextLogo/> is that someone.</p><p>It may not be a person, but it was created by people who have been through this and who care enough to want to help.</p>
                    <p>It will be there in those moments, proactively helping make a different choice. It is a hand reached out in support to fellow addicts.</p>
                    <p>We get it. We've got your back... in the moments when you need it the most.</p>
                </div>
            {/*</div>*/}
            {/*<div className={'B'}>*/}
            {/*    Wotcha*/}
            {/*</div>*/}
        {/*</div>*/}
    </Container>;
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  
  h2 {
    text-align: center;
  }
  @media (${Media.laptop}) {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  
  //.row {
  //  max-width: 64rem;
  //  display: grid;
  //  grid-template-columns: 2fr 1fr;
  //  grid-column-gap: 4rem;    
  //  align-items: start;
  //  justify-content: center;
  //}

  .blurb {
    max-width: 64rem;
    padding: 0 2rem; 
    
    @media (${Media.laptop}) {
      font-size: 0.9rem;
      column-count: 3;      
      
      &>p:first-of-type {
        padding-top: 0;   
        margin-top: 0;   
      }
    }
  }
`;
