import React, {ReactNode} from 'react';
import styled from 'styled-components';
// @ts-ignore
import Scribble from "../images/highlight_scribble.svg";
import classNames from "classnames";
import {Fonts, Media} from "../constants";


export type ScribbledHighlightProps = {
    className?: string;
    children: ReactNode;
    rotation?: number;
    scale?: number;
};
const ClassName = 'HighlightScribble';
export function HighlightScribble({className, children, rotation, scale}: ScribbledHighlightProps) {
    return <Container className={classNames(ClassName, className)} rotation={rotation} scale={scale}>
        <img src={Scribble} className={`${ClassName}-bkg`}/>
        <div className={`${ClassName}-content`}>
            {children}
        </div>
    </Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: visible;
  height: 8em;
  width: 14em;
  
  .${ClassName}-bkg {
    position: absolute;
    height: ${({scale=1.1}) => 100 * scale}%;
    width: ${({scale=1.1}) => 100 * scale}%;
    transform: rotate(${({rotation=0}) => rotation}deg);
    left: -10%;
    
    //z-index: 0;
  }
  .${ClassName}-content {
    font-family: ${Fonts.IndieFlower};
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    text-align: center;
    font-size: 10pt;
    line-height: 1rem;
    
    @media (${Media.tablet}) {
      font-size: 1.2rem;      
    }
    
    //z-index: 1;
  }
`;


