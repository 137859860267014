import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
// @ts-ignore
import {PageProps} from "gatsby";
import AboutHero from "../sections/about/AboutHero";
import {TryApp} from "../sections/TryApp";
import {TheTeam} from "../sections/about/TheTeam";
import {TheHistory} from "../sections/about/TheHistory";
import {TheProduct} from "../sections/about/TheProduct";
import {UTMs} from "../utms";


export default function About(props: PageProps) {
    return (
        <Layout {...props} meta={{title: 'About'}}>
            <div id={"about"}/>
            <AboutHero/>
            <TheHistory />
            <TheProduct />
            <TheTeam/>
            <TryApp asSection utm={UTMs.subscribe_about_tryapp} />
        </Layout>
    )
}
